/* Forms */

.form-group {
  margin-bottom: 1.5rem;
}

.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;
  .input-group-text {
    border-color: $border-color;
    padding: 0.575rem 0.75rem;
    color: $input-placeholder-color;
    background: none;
  }
  button {
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
}

.custom-control {
  .custom-control-label {
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.form-control {
  border: 1px solid $border-color;
  font-family: $type1;
  font-size: $input-placeholder-font-size;
  font-weight: normal;
  padding: 0.625rem 0.6875rem;
  background-color: $input-bg;
  border-radius: 2px;
  &::placeholder {
    color: $text-muted;
  }
  &:focus {
    border: 1px solid rgba(71, 164, 71, 0.5);
    background: inherit;
  }
}

select {
  &.form-control {
    padding: .4375rem .75rem;
    border: 0;
    outline: 1px solid $border-color;
    color: $input-placeholder-color;
    &:focus {
      outline: 1px solid $border-color;
    }
    @each $color,
    $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;
        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
}

.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}
